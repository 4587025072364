import {
  Add,
  AddHomeWork,
  AddModerator,
  GroupAdd,
  PersonAdd,
} from "@mui/icons-material";
import {
  Backdrop,
  Button,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Stack,
} from "@mui/material";
import { organizationsApi, usersApi } from "api";
import { getTranslation, toDataUrl } from "common";
import { useSnackbar, useTranslations, useUser } from "hooks";
import React, { useRef, useState } from "react";
import {
  GroupDialog,
  InviteUserDialog,
  OrganizationDialog,
  RoleDialog,
} from "./dialogs";
import { Priority } from "hooks/usePriorities";
import { putPrioritiesOnServer } from "common/utilities";

interface ButtonBarProps {
  organizationId?: number | null;
  getOrganizations: React.MutableRefObject<Function | undefined>;
  getGroups: React.MutableRefObject<Function | undefined>;
  // getUsers: React.MutableRefObject<Function | undefined>;
  getInvitedUsers: React.MutableRefObject<Function | undefined>;
  getRoles: React.MutableRefObject<Function | undefined>;
  administrator: boolean;
}

const ButtonBar: React.FC<ButtonBarProps> = ({
  organizationId,
  getOrganizations,
  getGroups,
  // getUsers,
  getInvitedUsers,
  getRoles,
  administrator
}) => {
  const translations = useTranslations();
  const user = useUser();
  const { sendSnack } = useSnackbar();
  const [organization, setOrganization] = useState<boolean>(false);
  const [group, setGroup] = useState<{} | null>(null);
  const [role, setRole] = useState<{} | null>(null);
  const [invitingUser, setInvitingUser] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const clickable = useRef(true)

  let speedActionStyle = {};
  if (!organizationId) {
    speedActionStyle = {
      color: "rgba(0,0,0,0.26)",
      boxShadow: "none",
      background: "rgba(0,0,0,0.12)",
    };
  }
  const createNewOrganization = async (
    imageUrl: string | null,
    name: string | null,
    priorities: Priority[]
  ) => {
    if(clickable){
    clickable.current = false;
    setLoading(true);
    try {
      let icon = null;
      if (imageUrl) {
        icon = await toDataUrl(imageUrl);
      }
      const createRes = await organizationsApi.post("", { name, icon });
      setOrganization(false);
      await putPrioritiesOnServer(priorities, createRes.data.id);
      sendSnack({
        message: `${createRes.data.responsemessage}`,
        type: "success",
      });
      getOrganizations.current && getOrganizations.current();
    } catch {
    } finally {
      setTimeout(() => clickable.current = true)
      setLoading(false);
    }
  }
  };
  const createNewGroup = async (
    imageUrl: string | null,
    name: string | null
  ) => {
    if (clickable.current){
    clickable.current = false;
    setLoading(true);
    try {
      let icon = null;
      if (imageUrl) {
        icon = await toDataUrl(imageUrl);
      }
      const createRes = await organizationsApi.post(`${organizationId}/groups`, { name, icon });
      setGroup(null);
      sendSnack({
        message: `${createRes.data.responsemessage}`,
        type: "success",
      });
      getGroups.current && getGroups.current();
    } catch {
    } finally {
      setTimeout(() => clickable.current = true)
      setLoading(false);
    }
  }
  };

  const createNewRole = async (
    imageUrl: string | null,
    name: string | null
  ) => {
    if (clickable.current){
    clickable.current = false;
    setLoading(true);
    try {
      let icon = null;
      if (imageUrl) {
        icon = await toDataUrl(imageUrl);
      }
      const createRes = await organizationsApi.post(`${organizationId}/roles`, { name, icon });
      setRole(null);
      sendSnack({
        message: `${createRes.data.responsemessage}`,
        type: "success",
      });
      getRoles.current && getRoles.current();
    } catch {
    } finally {
      setTimeout(() => clickable.current = true)
      setLoading(false);
    }
  }
  };
  const handleInviteUser = async (email: string | null) => {
    if (clickable.current) {
      clickable.current = false;
      setLoading(true);
      try {
        const createRes = await usersApi.post(
          `${user?.userId}/organizations/${organizationId}/inviteusers/dashboard`,
          { email: [email] }
        );
        setInvitingUser(false);
        sendSnack({
          message: `${createRes.data.responsemessage}`,
          type: "success",
        });
        getInvitedUsers.current && getInvitedUsers.current();
      } catch {
      } finally {
        setTimeout(() => clickable.current = true)
        setLoading(false);
      }
    }
  };

  return (
    <React.Fragment>
      <Backdrop open={open} sx={{ zIndex: (_) => _.zIndex.drawer + 1 }} />
      <SpeedDial
        ariaLabel="MobileButtonBars"
        sx={{
          display: { xs: "flex", md: "none" },
          zIndex: (_) => _.zIndex.drawer + 2,
          position: "absolute",
          bottom: 16,
          right: 16,
        }}
        icon={<SpeedDialIcon />}
        onClose={(e, reason) => {
          reason === "blur" || setOpen(false);
        }}
        onOpen={() => setOpen(true)}
        open={open}
      >
        <SpeedDialAction
          onClick={() => {
            setOpen(false);
            setOrganization(true);
          }}
          sx={{
            "& .MuiSpeedDialAction-staticTooltipLabel": {
              textAlign: "end",
              width: "max-content",
            },
          }}
          icon={<AddHomeWork />}
          tooltipTitle={getTranslation(
            translations,
            "organizations.button.create"
          )}
          tooltipOpen
        />
        <SpeedDialAction
          FabProps={{ disabled: !organizationId }}
          onClick={() => {
            if (organizationId) {
              setOpen(false);
              setGroup({});
            }
          }}
          sx={{
            "& .MuiSpeedDialAction-staticTooltipLabel": {
              textAlign: "end",
              width: "max-content",
              ...speedActionStyle,
            },
          }}
          icon={<GroupAdd />}
          tooltipTitle={getTranslation(translations, "groups.button.create")}
          tooltipOpen
        />
        <SpeedDialAction
          FabProps={{ disabled: !organizationId }}
          onClick={() => {
            if (organizationId) {
              setOpen(false);
              setRole({});
            }
          }}
          sx={{
            "& .MuiSpeedDialAction-staticTooltipLabel": {
              textAlign: "end",
              width: "max-content",
              ...speedActionStyle,
            },
          }}
          icon={<AddModerator />}
          tooltipTitle={getTranslation(translations, "roles.button.create")}
          tooltipOpen
        />
        <SpeedDialAction
          FabProps={{ disabled: !organizationId }}
          onClick={() => {
            if (organizationId) {
              setOpen(false);
              setInvitingUser(true);
            }
          }}
          sx={{
            "& .MuiSpeedDialAction-staticTooltipLabel": {
              textAlign: "end",
              width: "max-content",
              ...speedActionStyle,
            },
          }}
          icon={<PersonAdd />}
          tooltipTitle={getTranslation(translations, "users.button.inviteuser")}
          tooltipOpen
        />
      </SpeedDial>
      <Stack
        sx={{ mt: "0px !important", display: { xs: "none", md: "flex" } }}
        direction="row"
        spacing={2}
      >
        <Button
          id="btn-organizations-card"
          onClick={() => setOrganization(true)}
          size="small"
          variant="outlined"
          startIcon={<Add />}
        >
          {getTranslation(translations, "organizations.button.create")}
        </Button>
        {administrator && (
          <Button
            id="btn-groups-card"
            disabled={!organizationId}
            onClick={() => setGroup({})}
            size="small"
            variant="outlined"
            startIcon={<Add />}
          >
            {getTranslation(translations, "groups.button.create")}
          </Button>
        )}
        {administrator && (
          <Button
            id="btn-roles-card"
            disabled={!organizationId}
            onClick={() => setRole({})}
            size="small"
            variant="outlined"
            startIcon={<Add />}
          >
            {getTranslation(translations, "roles.button.create")}
          </Button>
        )}
        {administrator && (
          <Button
            id="btn-invite-card"
            disabled={!organizationId}
            onClick={() => setInvitingUser(true)}
            size="small"
            variant="outlined"
            startIcon={<Add />}
          >
            {getTranslation(translations, "users.button.inviteuser")}
          </Button>
        )}
        <OrganizationDialog
          loading={loading}
          open={organization}
          onClose={() => setOrganization(false)}
          organization={null}
          onSave={createNewOrganization}
        />
        <GroupDialog
          loading={loading}
          open={Boolean(group)}
          onClose={() => setGroup(null)}
          group={group}
          onSave={createNewGroup}
        />
        <RoleDialog
          loading={loading}
          open={Boolean(role)}
          onClose={() => setRole(null)}
          role={role}
          onSave={createNewRole}
        />
        <InviteUserDialog
          loading={loading}
          open={invitingUser}
          onClose={() => setInvitingUser(false)}
          onSave={handleInviteUser}
        />
      </Stack>
    </React.Fragment>
  );
};

export default ButtonBar;
