/* eslint-disable react-hooks/exhaustive-deps */
import { Cancel, Delete, Save } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { ImagePicker } from "components";
import React, { useEffect, useState } from "react";
import { Role } from "../RolesPanel";
import DialogHeader from "./DialogHeader";
import { useBreakpoint, useTranslations, useForm } from "hooks";
import { getTranslation } from "common";
import { Validation, isRequired } from "hooks/useForm";
import { LoadingButton } from "@mui/lab";
interface RoleDialogProps {
  open: boolean;
  role: Role | null;
  onClose: () => void;
  onDelete?: () => void;
  onSave: (photo: string | null, name: string | null) => Promise<void>;
  loading: boolean
}

const RoleDialog: React.FC<RoleDialogProps> = ({
  open,
  role,
  onClose,
  onSave,
  onDelete,
  loading,
}) => {
  const translations = useTranslations();
  const [saveLoading, setSaveLoading]=useState<boolean>(false)
  const validations: Validation[] = [
    ({ name }) =>
      isRequired(name) || {
        name: getTranslation(translations, "placeholders.nameisrequired.label"),
      },
  ]
  const isMobile = useBreakpoint("down", "sm")
  const {
    reset,
    isValid,
    changeHandler,
    values,
    errors,
    touched,
    setValues,
  } = useForm({
    name: role?.name || "",
    icon: role?.icon || "",
  },
    validations
  );

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open]);

  useEffect(() => {
    setValues(role)
  }, [role]);
  return (
    <Dialog fullScreen={isMobile} open={open} onClose={() => onClose()}>
      <DialogHeader onClose={onClose}>
        {getTranslation(translations, "roles.edit.suggestnew")}
      </DialogHeader>
      <DialogContent>
        <Stack direction="row" sx={{ pt: (_) => _.spacing(0.7) }} spacing={2}>
          <Box flex={1}>
            <ImagePicker
              photoUrl={values?.icon || ""}
              relativePath={role?.icon || ""}
              setPhotoUrl={(value) => changeHandler("icon", value)}
            />
          </Box>
          <Grid component="form" flex={2} container>
            <Grid item xs={12}>
              <TextField
                id="roles-dialog"
                variant="outlined"
                fullWidth
                size="small"
                label={getTranslation(translations, "placeholders.role.name.label")}
                // inputProps={register("name")}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                value={values?.name || ""}
                onChange={({ target }) => changeHandler("name", target.value)}
              />
            </Grid>
            <Grid
              item
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
              xs={12}
            >
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                spacing={2}
              >
                {onDelete && (
                  <Button
                    id="btn-cancel-role"
                    variant="contained"
                    // color="error"
                    startIcon={<Delete />}
                    size="small"
                    onClick={() => onDelete()}
                  >
                    {getTranslation(translations, "user.buttondelete.label")}
                  </Button>
                )}
                <Button
                  variant="contained"
                  // color="warning"
                  startIcon={<Cancel />}
                  size="small"
                  onClick={() => onClose()}
                >
                  {getTranslation(translations, "user.buttoncancel.label")}
                </Button>
                <LoadingButton
                  id="btn-save-role"
                  variant="contained"
                  loading={saveLoading || loading}
                  loadingPosition="start"
                  // color="success"
                  disabled={!isValid || loading}
                  startIcon={<Save />}
                  type="submit"
                  size="small"
                  onClick={async (e) => {
                    e.preventDefault();
                    setSaveLoading(true);
                  await onSave(values.icon, values.name);
                  setSaveLoading(false);
                  }}
                >
                  {getTranslation(translations, "pm.button.save")}
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default RoleDialog;
