import { organizationsApi, robotsApi } from "api";
import { useCallback, useEffect, useState } from "react";
import useSelectedOrganization from "./useSelectedOrganization";
import dayjs from "dayjs";

interface User{
  id:number;
  firstname:string;
  lastname:string;
  picture:string;
  email:string;
}

interface Organization{
  id: string;
  name:string;
  administrator:boolean;
  creation:string;
  deleted:boolean;
  icon: string | null;
}

export interface Robot {
  expiration_date: string;
  name: string;
  iduser: number;
  iduserowner: number;
  description: string;
  organizations: Organization[];
  orgjsonschema: string;
  orguischema: string;
  actjsonschema: string;
  actuischema: string;
  gtwjsonschema: string;
  gtwuischema: string;
  evtjsonschema: string;
  evtuischema: string;
  apikey: string;
  published: boolean;
  urlpushnotification: string;
  picture: string | null;
  id: number;
  user: User;
}

const useRobots = (mode: "user" | "organization"): [Robot[], () => Promise<void>] => {
  const [robots, setRobots] = useState<Robot[]>([]);
  const organization = useSelectedOrganization();

  const getOrganizationRobots = useCallback(async () => {
    try {
      if (organization) {
        const { data } = await organizationsApi.get(`${organization.id}/robots`);
        setRobots(data.robots.map((robot: Robot) => ({ ...robot, expiration_date: dayjs(robot.expiration_date).format("DD-MM-YYYY HH:mm") })));
     //   console.log(data)
      }
    } catch { } finally { }
  }, [organization]);

  const getUserRobots = useCallback(async () => {
    try {
      if (organization) {
        const { data } = await robotsApi.get("");
        setRobots(data.robots.map((robot: Robot) => ({ ...robot, expiration_date: dayjs(robot.expiration_date).format("DD-MM-YYYY HH:mm") })));
      }
    } catch { } finally { }
  }, [organization]);

  useEffect(() => {
    if (mode === "user") {
      getUserRobots()
    } else if (mode === "organization") {
      getOrganizationRobots();
    }
  }, [getOrganizationRobots, getUserRobots, mode]);

  return [robots, mode==="user" ? getUserRobots : mode==="organization" ? getOrganizationRobots : async() => void console.log("not implemented") ];
};

export default useRobots;
