import React, { ErrorInfo } from "react";
import { Notifier } from "@airbrake/browser";

class ErrorBoundary extends React.Component<React.PropsWithChildren> {
  airbrake: Notifier;
  state: { error: undefined | string; hasError: boolean } = {
    error: undefined,
    hasError: false,
  };
  private promiseRejectionHandler = (event: PromiseRejectionEvent) => {
    this.setState({
      error: String(event.reason),
    });
  };
  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state = { hasError: false, error: undefined };
    this.airbrake = new Notifier({
      host: process.env.REACT_APP_ERRBIT_URL,
      projectId: 1,
      projectKey: process.env.REACT_APP_ERRBIT_PROJECT_KEY,
    });
    this.airbrake.addFilter((notice) => {
      notice.context.version = process.env.REACT_APP_VERSION;
      return notice;
    });
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    
    // Display fallback UI
    this.setState({ hasError: true });
    // Send error to Airbrake
    this.airbrake.notify({
      error: error,
      params: { info: info },
    });
  }
  componentDidMount() {
    // Add an event listener to the window to catch unhandled promise rejections & stash the error in the state
    window.addEventListener("unhandledrejection", this.promiseRejectionHandler);
  }

  componentWillUnmount() {
    window.removeEventListener(
      "unhandledrejection",
      this.promiseRejectionHandler
    );
  }
  render() {
    if (this.state.hasError || this.state.error) {
      // You can render any custom fallback UI
      return <h1>{this.state.error || "Something went wrong."}</h1>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
