import { Menu, MenuItem } from "@mui/material";
import { organizationsApi } from "api";
import React, { useCallback, useEffect, useState } from "react";
import { AssociatedUser, User } from "components/UserItem";
import UserAvatar from "components/UserAvatar";

interface UsersMenuProps {
    anchor: null | HTMLElement;
    onLink: (id: number) => void;
    associated: Array<AssociatedUser>;
    onClose: Function;
    organizationId: number;
}

const UsersMenu: React.FC<UsersMenuProps> = ({
    anchor,
    onLink,
    associated,
    onClose,
    organizationId,
}) => {
    const [voices, setVoices] = useState<Array<User>>([]);

    const getUsers = useCallback(async () => {
        const response = await organizationsApi.get(`/${organizationId}/users`)
        setVoices(
            response.data.users.filter(
                (x: User) => !associated.map((_) => _.id).includes(x.id)
            )
        );
    }, [organizationId, associated]);

    useEffect(() => {
        if (Boolean(anchor)) {
            getUsers();
        }
    }, [anchor, getUsers]);

    return (
        <Menu open={voices.length > 0 ? Boolean(anchor) : false} onClose={() => onClose()} anchorEl={anchor}>
            {voices.map(({ id, ...rest }) => (
                <MenuItem id={`menu-user-${id}`} key={`menu-user-${id}`} onClick={() => onLink(id)} sx={{ "& .MuiFormHelperText-root": { color: _theme => _theme.palette.text.primary } }}>
                    <UserAvatar disableHugePicture user={{ ...rest }} showUsername />
                </MenuItem>
            ))}
        </Menu>
    );
};

export default UsersMenu;
