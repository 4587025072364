/*********************************
 ****** REACT CONFIGURATION ******
 *********************************/
import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import reportWebVitals from "reportWebVitals";
/*********************************
 ****** REDUX CONFIGURATION ******
 *********************************/
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import store from "store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
/*****************
 ****** CSS ******
 *****************/
import "index.css";

import App from "App";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "common";
import { AuthProvider, UtilityProvider } from "contexts";
import "cronstrue/locales/it";
import "cronstrue/locales/en";
import "cronstrue/locales/es";
import dayjs from "dayjs";
import "dayjs/locale/it";
import "dayjs/locale/en";
import "dayjs/locale/de";
import "dayjs/locale/es";
import "dayjs/locale/es-pr";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import ErrorBoundary from "components/airbrake";

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);

const persistor = persistStore(store);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
// expose store when run in Cypress
if ((window as any).Cypress) {
  (window as any).store = store;
}

// process.env.REACT_APP_GOOGLE_CLIENT_ID ||
root.render(
  // <React.StrictMode>
  <ErrorBoundary>
    <GoogleOAuthProvider
      clientId={
        "747487967968-dfsm83ba1opbv569osruc9mpqah3q666.apps.googleusercontent.com"
      }
    >
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <UtilityProvider>
                <HashRouter>
                  <App />
                </HashRouter>
              </UtilityProvider>
            </PersistGate>
          </Provider>
        </ThemeProvider>
      </AuthProvider>
    </GoogleOAuthProvider>
  </ErrorBoundary>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
