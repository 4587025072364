import { Delete, Loop, PhotoCamera, Undo } from "@mui/icons-material";
import { Avatar, Box, Card, IconButton, Stack } from "@mui/material";
import { baseApi } from "api";
import React, { useEffect, useRef, useState } from "react";

interface ImagePickerProps {
  // initialPhoto: string | null;
  relativePath: string | null;
  photoUrl: string | null;
  setPhotoUrl: React.Dispatch<React.SetStateAction<string | null>>;
}

const ImagePicker: React.FC<ImagePickerProps> = ({
  photoUrl,
  setPhotoUrl,
  relativePath,
  // initialPhoto,
}) => {
  const [initialPhoto, setInitialPhoto] = useState<string | null>(null);
  const fileRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (relativePath) {
      (async () => {
        try {
          const res = await baseApi.get(relativePath || "");
          if (res.data) {
            const contentType = res.data.split(";")[0].replace("data:", "");
            const b64 = res.data.split(";")[1].replace("base64,", "");
            const byteCharacters = atob(b64);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: contentType });
            const _photoUrl = URL.createObjectURL(blob);
            setInitialPhoto(_photoUrl);
            setPhotoUrl(_photoUrl);
          }
        } catch (e) {
          console.error(e);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relativePath]);

  const resetPicture = () => {
    setPhotoUrl(initialPhoto);
  };
  const changePicture = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (target && target.files && target.files[0]) {
      setPhotoUrl(URL.createObjectURL(target.files[0]));
    }
  };
  const deleteTempPhoto = () => {
    fileRef.current &&
      // cannot set value to null cause it is not a string but we need it
      // eslint-disable-next-line no-eval
      (fileRef.current.value = eval("null"));
    setPhotoUrl(null);
  };

  return (
    <Box
      sx={{
        flex: 1,
        minWidth: 175,
        maxWidth: 300,
        height: "auto",
        position: "relative",
      }}
    >
      <Stack
        sx={{
          position: "absolute",
          top: 10,
          right: 10,
          zIndex: 2,
        }}
        direction="row"
        spacing={2}
      >
        {initialPhoto && photoUrl !== initialPhoto && (
          <IconButton
            sx={{
              backgroundColor: "rgba(0,0,0,0.3)",
              color: (theme) => theme.palette.common.white,
            }}
            onClick={resetPicture}
          >
            <Undo />
          </IconButton>
        )}
        {photoUrl && (
          <IconButton
            sx={{
              backgroundColor: "rgba(0,0,0,0.3)",
              color: (theme) => theme.palette.common.white,
            }}
            onClick={deleteTempPhoto}
          >
            <Delete />
          </IconButton>
        )}
        <IconButton
          sx={{
            backgroundColor: "rgba(0,0,0,0.3)",
            color: (theme) => theme.palette.common.white,
          }}
          aria-label="upload picture"
          component="label"
        >
          <input
            ref={fileRef}
            hidden
            accept="image/*"
            type="file"
            onChange={changePicture}
          />
          {!photoUrl ? <PhotoCamera /> : <Loop />}
        </IconButton>
      </Stack>
      <Avatar
        sx={{ height: "auto", width: "100%", maxWidth: 300 }}
        variant="rounded"
        component={Card}
        src={photoUrl ?? undefined}
      />
    </Box>
  );
};

export default ImagePicker;
