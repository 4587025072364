import React from "react";
import { Apartment, Diversity3, MenuOpen } from "@mui/icons-material";
import {
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { getTranslation } from "common";
import { addSelectedOrganization } from "Redux/organizationReducer";
import {
  useOrganizations,
  useSelectedOrganization,
  useTranslations,
} from "hooks";
import AvatarRem from "./AvatarRem";

const SelectedOrganization = () => {
  const { pathname } = useLocation();
  const translations = useTranslations();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const dispatch = useDispatch();
  const selectedOrganization = useSelectedOrganization();
  const [organizations] = useOrganizations();
  const updateSelectedOrganization = (id?: number | null) => {
    dispatch(addSelectedOrganization({ id }));
    setAnchorEl(null);
  };
  const listOfAllOrganizations = [...organizations];
  const shouldShowMenuIcon =
    !pathname.includes("edit") && !pathname.includes("insert");
  return (
    <Stack direction="row-reverse" spacing={1} p={1} alignItems="center">
      {shouldShowMenuIcon && (
        <Tooltip title={getTranslation(translations, "organizations.name")}>
          <IconButton
            sx={{
              mr: "8px",
              color: "rgba(84, 189, 193, 1)",
              borderRadius: "6px",
              // backgroundColor: "rgba(84, 189, 193, 0.3)",
              // "&:hover": {
              //   backgroundColor: "rgba(84, 189, 193, 0.4)",
              // },
            }}
            id="org-icon"
            onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
          >
            <MenuOpen />
          </IconButton>
        </Tooltip>
      )}
      {selectedOrganization && selectedOrganization.id ? (
        <React.Fragment>
          <AvatarRem
            sx={{ width: "40px" }}
            icon={selectedOrganization?.icon}
            alt={selectedOrganization.name}
          >
            <Diversity3 />
          </AvatarRem>
        </React.Fragment>
      ) : (
        <Stack direction="row" spacing={2}>
          <Apartment color="primary" />
          <Typography>
            {getTranslation(translations, "pm.select_organization")}
          </Typography>
        </Stack>
      )}
      <Typography
        variant="subtitle1"
        sx={{ wordBreak: "break-word", textTransform: "capitalize", display: { xs: "none", md: "block"} }}
      >
        {selectedOrganization?.name}
      </Typography>
      <Menu
        id="listof-org"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {listOfAllOrganizations.map((org) => (
          <MenuItem
            key={org.id}
            sx={{
              "&.Mui-selected": {
                backgroundColor: (_) => `${_.palette.primary.light}55`,
              },
            }}
            selected={org.id === selectedOrganization?.id}
            onClick={() => updateSelectedOrganization(org.id)}
          >
            <Typography>{org?.name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
};

export default SelectedOrganization;
