import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
  useMemo,
} from "react";
//@ts-ignore
import BpmnViewer from "bpmn-js/lib/NavigatedViewer";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Alert,
  Box,
  ButtonGroup,
  Dialog,
  InputAdornment,
  Snackbar,
  TextField,
  Link as MUILink,
  Fab,
  Menu,
  ListItemText,
  Container,
  MenuItem,
  ListItemIcon,
  Typography,
} from "@mui/material";
import NoModels from "image/NoModels.png";
import { Button } from "@mui/material";
import { createPath, getTranslation } from "common";

import { Stack } from "@mui/material";

import { selectDefaultOrganization } from "../../../../Redux/organizationReducer";
import {
  ProcessModelFolderType,
  RevisionModelType,
  SelectedProcessModelType,
} from "../../../../model/processModel";
import NewFolderModelPopUp from "../components/NewFolderModelPopUp";
import DeleteFolderPopUpTypes from "../components/DeleteFolderPopUp";
import {
  useTree,
  useTranslations,
  useSnackbar,
  useSelectedOrganization,
} from "hooks";
import TreeView from "./TreeView";

import "./Process.css";
import {
  Add,
  ArrowDownward,
  ArrowUpward,
  DashboardCustomize,
  Folder,
  GetApp,
} from "@mui/icons-material";
import { addExpandedNodes } from "Redux/processReducer";
import { processmodelsApi } from "api";
import InputPopUp from "components/dialogs/InputPopUp";
import { Translations } from "model/messageModel";
import { SortState } from "hooks/useSortableList";

interface SearchBarProps {
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  searchText: string;
  setHighlighted: React.Dispatch<React.SetStateAction<number | null>>;
  decreaseIdx: () => void;
  increaseIdx: () => void;
  searchResults: (ProcessModelFolderType | SelectedProcessModelType)[];
  searchIdx: number;
  translations: Translations;
}
const SearchBar: React.FC<SearchBarProps> = ({
  setSearchText,
  searchText,
  setHighlighted,
  decreaseIdx,
  increaseIdx,
  searchResults,
  searchIdx,
  translations,
}) => {
  return (
    <TextField
      size="small"
      id="search-bar-model"
      onBlur={() => setHighlighted(null)}
      onKeyUp={(e) =>
        e.key === "Enter" && (e.shiftKey ? decreaseIdx() : increaseIdx())
      }
      InputProps={{
        sx: { pr: 0 },
        endAdornment: (
          <InputAdornment position="end">
            {searchResults.length ? searchIdx + 1 : 0}/{searchResults.length}
            <ButtonGroup sx={{ height: 40 }} color="inherit" size="small">
              <Button onClick={decreaseIdx} size="small" variant="text">
                <ArrowUpward />
              </Button>
              <Button onClick={increaseIdx} size="small" variant="text">
                <ArrowDownward />
              </Button>
            </ButtonGroup>
          </InputAdornment>
        ),
      }}
      label={getTranslation(
        translations,
        "pm.organizationchart.ricerca.placeholder"
      )}
      value={searchText}
      onChange={({ target }) => {
        setSearchText(target.value);
      }}
    />
  );
};

export default function Process() {
  const navigate = useNavigate();
  const translations = useTranslations();
  const [searchResults, setSearchResult] = useState<
    (ProcessModelFolderType | SelectedProcessModelType)[]
  >([]);
  const [highlighted, setHighlighted] = useState<number | null>(null);
  const [searchIdx, setSearchIdx] = useState<number>(0);
  const [searchEnabled, setSearchEnabled] = useState<boolean>(true);
  const [errorModel, setErrorModel] =
    useState<SelectedProcessModelType | null>();
  const [showCreateProcessModal, setCreateProcessModal] =
    useState<boolean>(false);
  const [showNewFolderModel, setShowNewFolderModel] = useState<boolean>(false);
  const [deleteFolderPopUp, setDeleteFolderPopUp] = useState<boolean>(false);
  // const [orderbyValue] = useState<string>("asc");

  const [errorInModel, setErrorInModel] = useState<Boolean>(false);
  // const [publishErrorMessage, setPublishErrorMessage] = useState<string>("");
  // const [dePublishErrorMessage, setDePublishErrorMessage] =
  //   useState<string>("");
  // const [, setPublishSuccessMessage] = useState<string>('');
  // const [showFolderCreatedSuccessMessage, setShowFolderCreatedSuccessMessage] =
  //   useState(false);
  // const [showDeletedFolderSuccessMessage] = useState(false);
  // const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");

  const [orderBy, setOrderBy] = useState<SortState>(SortState.Asc);
  const {
    plain,
    processModelFolders,
    processModelList,
    expandedNodes,
    setSelectedNode,
    getTreeNodes,
    updateTree,
    createNewNode,
    updateNodeTitle,
    deleteNode,
    setExpandedNode,
  } = useTree();
  const [selectedProcessModel, setSelectedProcessModel] =
    useState<SelectedProcessModelType | null>(null);
  const [selectedRevision, setSelectedRevision] =
    useState<RevisionModelType | null>(null);

  const dispatch = useDispatch();
  const { sendSnack } = useSnackbar();
  const selectedOrganization = useSelectedOrganization();
  const isAdmin = selectedOrganization?.administrator;
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (!selectedOrganization?.id) {
      dispatch(selectDefaultOrganization({}));
    }
    // eslint-disable-next-line
  }, []);

  async function openDiagram(xml: string, scrollIntoView: boolean = true) {
    const id = "previewer-canvas";
    const canvas = document.getElementById(id);
    if (canvas) {
      canvas.innerHTML = "";
      let bpmnViewer = new BpmnViewer({
        container: canvas,
        readOnly: true,
        selectable: false,
        additionalModules: [],
      });

      try {
        await bpmnViewer.importXML(xml);
        const canvas: any = bpmnViewer.get("canvas");
        canvas.zoom("fit-viewport");
        // bpmnViewer.on("element.dblclick", 1500, stopPropagation);
        // bpmnViewer.on("element.click", 1500, stopPropagation);
        // bpmnViewer.on("element.drag", 1500, stopPropagation);
      } catch (err) {
        console.error(err);
      }
    }
  }
  const getProcessModelXml = async (modelId: number) => {
    try {
      const { data } = await processmodelsApi.get(`/${modelId}`);
      return data.model;
    } catch {
      // console.error(e);
    } finally {
    }
  };

  useEffect(() => {
    if (selectedProcessModel) {
      if (
        !processModelList.find(
          (processmodel) => selectedProcessModel.id === processmodel.id
        )
      ) {
        setSelectedProcessModel(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processModelList]);

  useEffect(() => {
    (async () => {
      let diagram: string | undefined;
      if (selectedProcessModel) {
        diagram = await getProcessModelXml(selectedProcessModel.id);
      } else {
        const id = "previewer-canvas";
        const canvas = document.getElementById(id);
        if (canvas) {
          canvas.innerHTML = "";
        }
      }
      if (!!diagram) {
        openDiagram(diagram);
      }
    })();
    // eslint-disable-next-line
  }, [selectedProcessModel]);

  useEffect(() => {
    (async () => {
      let diagram: string | undefined;
      if (selectedRevision) {
        diagram = selectedRevision.model;
      }
      if (!!diagram) {
        openDiagram(diagram);
      }
    })();
    // eslint-disable-next-line
  }, [selectedRevision]);

  const handleCreateNewFolder = (folderName: string) => {
    createNewNode(folderName, (data) => {
      setShowNewFolderModel(false);
      sendSnack({
        message: data.responsemessage,
        type: "success",
      });
    });
  };

  const [nameModelPopUp, setNameModelPopUp] = useState<boolean>(false);

  const [fileContent, setFileContent] = useState<string>("");

  const handleFileChange = (e: any) => {
    const selectedFile = (e.target as HTMLInputElement)?.files?.[0];

    if (selectedFile) {
      const fileReader = new FileReader();

      fileReader.onload = (event) => {
        const content = event.target?.result as string;
        setFileContent(content);
        setNameModelPopUp(true);
        // importModel(fileContent)
      };

      fileReader.readAsText(selectedFile);
    }
  };

  const importModel = async (nameModel: string) => {
    try {
      if (fileContent) {
        // const formData = new FormData();
        // formData.append('xmlFile', file);

        const { data } = await processmodelsApi.post("", {
          model: fileContent,
          idorganization: selectedOrganization?.id,
          name: nameModel,
        });
        sendSnack({
          message: data.responsemessage,
          type: "success",
        });
        setRefresh(true);
      } else {
        sendSnack({
          message: getTranslation(
            translations,
            "generic.no_file_selected.caption"
          ),
          type: "warning",
        });
      }
    } catch (error) {
      // Gestisci gli errori
      console.error("Errore durante l'upload:", error);
    }
  };

  const handleImportModel = () => {
    // Crea un elemento di input di tipo file programmatically
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".flw,.txt";
    fileInput.onchange = handleFileChange;

    fileInput.click();
  };

  useEffect(() => {
    if (selectedOrganization?.id) {
      getTreeNodes();
      setSearchText("");
    }
    // eslint-disable-next-line
  }, [selectedOrganization, refresh]);

  const processError = (
    model: SelectedProcessModelType,
    errorsInModel: Boolean
  ) => {
    setErrorInModel(errorsInModel);
    setErrorModel(model);
  };

  const handleOnClickDeleteFolderIcon = (nodeId: Number) => {
    setSelectedNode(nodeId);
    setDeleteFolderPopUp(true);
  };

  const deleteNodeSuccessCallback = () => {
    // setShowFolderCreatedSuccessMessage(true);
    setDeleteFolderPopUp(false);
  };
  const filter = useCallback(
    (_: ProcessModelFolderType | SelectedProcessModelType) =>
      _.name?.toLowerCase().includes(searchText.toLowerCase()),
    [searchText]
  );

  useEffect(() => {
    if (!searchText) {
      setSearchResult([]);
      setSearchIdx(0);
      setSearchEnabled(false);
    } else {
      setSearchResult(plain.filter(filter));
      setSearchIdx(0);
      setSearchEnabled(true);
    }
  }, [filter, plain, searchText, searchEnabled]);

  useEffect(() => {
    const item = searchResults[searchIdx];
    if (item) {
      const pathToOpen = createPath(item, plain);
      dispatch(addExpandedNodes(pathToOpen.map((_) => String(_))));
      setHighlighted(item.id);
    }
  }, [searchIdx, searchResults, plain, dispatch, searchEnabled]);
  useLayoutEffect(() => {
    const item = searchResults[searchIdx];
    if (highlighted && item) {
      const isFolder = Object.keys(item).includes("children");
      let id = isFolder
        ? `folder-tree-item-${item.id}`
        : `model-item-${item.id}`;
      document
        .getElementById(id)
        ?.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  }, [highlighted, searchResults, searchIdx]);
  const increaseIdx = () =>
    setSearchIdx((_) => (searchResults.length <= _ + 1 ? 0 : _ + 1));
  const decreaseIdx = () =>
    setSearchIdx((_) => (0 > _ - 1 ? searchResults.length - 1 : _ - 1));

  const actions = [
    {
      name: getTranslation(translations, "pm.label.button.process_name"),
      icon: <DashboardCustomize />,
      callback: () => {
        navigate("/dashboard/process/insert");
      },
    },
    {
      name: getTranslation(translations, "pm.label.button.newfolder"),
      icon: <Folder />,
      callback: () => {
        setShowNewFolderModel(true);
      },
    },
    {
      name: getTranslation(translations, "pm.button.import_model"),
      icon: <GetApp />,
      callback: handleImportModel,
    },
  ];
  const [openFab, setOpenFab] = useState<EventTarget | null>(null);

  const toggleSortOrder = () => {
    setOrderBy((prevOrder) =>
      prevOrder === SortState.Asc ? SortState.Desc : SortState.Asc
    );
  };
  const sortedProcessModelList = [...processModelList].sort((a, b) => {
    const nameA = a.name || "";
    const nameB = b.name || "";
    if (orderBy === SortState.Asc) {
      return nameA.localeCompare(nameB);
    } else {
      return nameB.localeCompare(nameA);
    }
  });

  const sortedProcessModelFolders = useMemo(() => {
    const recursive = (items: ProcessModelFolderType[]) => {
      const sortedFolder = [...items].sort((a, b) => {
        const nameA = a.name || "";
        const nameB = b.name || "";
        if (orderBy === SortState.Asc) {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });
      sortedFolder.forEach(
        (item) => (item.children = recursive(item.children))
      );
      return sortedFolder;
    };
    if (processModelFolders.length > 0) {
      return recursive(
        JSON.parse(JSON.stringify(processModelFolders[0].children))
      );
    } else {
      return [];
    }
  }, [processModelFolders, orderBy]);

  return (
    <React.Fragment>
      <Stack height="100%" direction="row">
        <Container
          maxWidth="md"
          sx={{
            ml: 0,
            pl: "0px!important",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Stack
            sx={{
              height: "40px",
            }}
            direction="row"
            display={{ xs: "flex", md: "none" }}
            justifyContent={"space-between"}
            spacing={1}
          >
            <Fab
              sx={{ height: 40, width: 40 }}
              color="primary"
              onClick={(e) => setOpenFab(e.target)}
            >
              <Add />
            </Fab>
            <Menu
              anchorEl={openFab as Element}
              // onOpen={() => setOpenFab(true)}
              onClose={() => setOpenFab(null)}
              open={Boolean(openFab)}
            >
              {actions.map((action) => (
                <MenuItem key={action.name} onClick={action.callback}>
                  <ListItemIcon>{action.icon}</ListItemIcon>
                  <ListItemText primary={action.name} />
                </MenuItem>
              ))}
            </Menu>
            <Stack sx={{ maxWidth: 400 }} flex={1}>
              <SearchBar
                decreaseIdx={decreaseIdx}
                increaseIdx={increaseIdx}
                searchIdx={searchIdx}
                searchResults={searchResults}
                searchText={searchText}
                setHighlighted={setHighlighted}
                setSearchText={setSearchText}
                translations={translations}
              />
            </Stack>
          </Stack>
          <Stack
            sx={{
              height: "40px",
              alignItems: "center",
            }}
            direction="row"
            display={{ xs: "none", md: "flex" }}
            spacing={2}
          >
            {isAdmin && (
              <React.Fragment>
                <Button
                  // style={{ width: "30%" }}
                  style={{ minHeight: "37px" }}
                  size="small"
                  variant="outlined"
                  // startIcon={<Add />}
                  onClick={() => {
                    navigate("/dashboard/process/insert");
                  }}
                  // startIcon={<Add />}
                  id={"create-new-process-button"}
                >
                  {getTranslation(translations, "pm.label.button.process_name")}
                </Button>
                <Button
                  // style={{ width: "30%" }}
                  style={{ minHeight: "37px" }}
                  // startIcon={<Add />}
                  size="small"
                  variant="outlined"
                  // startIcon={<Add />}
                  onClick={() => {
                    setShowNewFolderModel(true);
                  }}
                  id={"create-new-folder-button"}
                >
                  {getTranslation(translations, "pm.label.button.newfolder")}
                </Button>
                <Button
                  size="small"
                  style={{ minHeight: "37px" }}
                  // startIcon={<ImportExport />}
                  variant="outlined"
                  // startIcon={<Add />}
                  onClick={handleImportModel}
                  id={"import-model"}
                >
                  {getTranslation(translations, "pm.button.import_model")}
                  <input
                    type="file"
                    style={{ display: "none" }} // Assicura che l'input file non sia visibile
                    accept=".flw,.txt"
                    onChange={handleFileChange} // Gestisce il cambiamento del file
                  />
                </Button>
              </React.Fragment>
            )}
            <SearchBar
              decreaseIdx={decreaseIdx}
              increaseIdx={increaseIdx}
              searchIdx={searchIdx}
              searchResults={searchResults}
              searchText={searchText}
              setHighlighted={setHighlighted}
              setSearchText={setSearchText}
              translations={translations}
            />
          </Stack>
          <Box
            sx={{
              flex: 1,
              pt: 2,
              maxWidth: "100%",
              overflow: "auto",
              // height: "100%",
              // maxHeight: "calc(100% - 40px)",
            }}
          >
            {processModelFolders?.length > 0 && (
              <React.Fragment>
                {processModelFolders[0].children.length <= 0 &&
                processModelList.length <= 1 ? (
                  <Box
                    height="100%"
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    px={5}
                    justifyContent="center"
                    alignItems="center"
                    position="relative"
                  >
                    <Box
                      component="img"
                      src={NoModels}
                      alt="No models"
                      // marginLeft={30}
                      width="60%"
                    />
                    <Typography variant="caption">
                      {getTranslation(
                        translations,
                        "generic.noprocesses.message"
                      )}
                    </Typography>
                  </Box>
                ) : (
                  <Box>
                    <Button onClick={toggleSortOrder} sx={{ marginTop: 2 }}>
                      {getTranslation(translations, "generic.sort.by.name")}{" "}
                      {orderBy === SortState.Asc ? (
                        <ArrowDownward />
                      ) : (
                        <ArrowUpward />
                      )}
                    </Button>
                    <TreeView
                      selectedProcessModel={selectedProcessModel}
                      setSelectedProcessModel={setSelectedProcessModel}
                      selectedRevision={selectedRevision}
                      setSelectedRevision={setSelectedRevision}
                      highlighted={highlighted}
                      handleUpdateTree={updateTree}
                      processModelFolders={sortedProcessModelFolders}
                      processModelList={sortedProcessModelList}
                      expandedNodes={expandedNodes}
                      setSelectedNode={setSelectedNode}
                      setExpandedNode={setExpandedNode}
                      returnmodel={processError}
                      handleUpdateFolderName={updateNodeTitle}
                      handleOnClickDeleteFolderIcon={
                        handleOnClickDeleteFolderIcon
                      }
                    />
                  </Box>
                )}
              </React.Fragment>
            )}
            <Snackbar
              anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
              open={Boolean(errorModel && errorInModel)}
            >
              <MUILink
                sx={{ textDecoration: "none" }}
                component={Link}
                to={`/dashboard/process/edit/${errorModel?.id}/?validate=1`}
              >
                <Alert variant="filled" severity="error">
                  {`${errorModel?.name}`}
                  <br />
                  {getTranslation(translations, "pm.list.publish.error")}
                </Alert>
              </MUILink>
            </Snackbar>
          </Box>
          <Dialog
            open={showCreateProcessModal}
            onClose={() => setCreateProcessModal(false)}
            fullWidth={true}
            maxWidth="xs"
          />
          <NewFolderModelPopUp
            open={showNewFolderModel}
            setClose={setShowNewFolderModel}
            handleSubmit={handleCreateNewFolder}
          />
          <DeleteFolderPopUpTypes
            open={deleteFolderPopUp}
            setClose={setDeleteFolderPopUp}
            handleSubmit={() => deleteNode(deleteNodeSuccessCallback)}
          />
          {/* <SnackbarMessage
        open={!!publishErrorMessage}
        autoHideDuration={5000}
        onClose={() => setPublishErrorMessage("")}
        sx={{
          top: "80px !important",
        }}
        severity="error"
        message={publishErrorMessage}
      />
      <SnackbarMessage
        open={!!dePublishErrorMessage}
        autoHideDuration={5000}
        onClose={() => setDePublishErrorMessage("")}
        sx={{
          top: "80px !important",
        }}
        severity="error"
        message={dePublishErrorMessage}
      />
      <SnackbarMessage
        open={
          showFolderCreatedSuccessMessage || showDeletedFolderSuccessMessage
        }
        autoHideDuration={5000}
        onClose={() => setShowFolderCreatedSuccessMessage(false)}
        sx={{
          top: "80px !important",
        }}
        severity="success"
        message={snackbarMessage}
      /> */}
          <InputPopUp
            labels={{
              title: getTranslation(translations, "generic.model_name.caption"),
              inputLabel: getTranslation(
                translations,
                "generic.insert_model_name.caption"
              ),
            }}
            callback={importModel}
            open={nameModelPopUp}
            onClose={() => setNameModelPopUp(false)}
          />
        </Container>
        <Box
          position="relative"
          display={{ xs: "none", lg: "flex" }}
          justifyContent="center"
          alignItems="center"
          minWidth="50%"
        >
          {/* <Backs */}
          <Box
            className="show-viewer-canvas"
            id={`previewer-canvas`}
            sx={{
              py: (_) => _.spacing(2),
              width: "calc(100%)",
              height: "100%",
              // display: "flex",
              flex: 1,
              pointerEvents: "none",
              "& .djs-palette": { display: "none" },
              "& .djs-palette-open": { float: "unset" },
            }}
          />
        </Box>
      </Stack>
    </React.Fragment>
  );
}
