import {
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { organizationsApi, rolesApi } from "api";
import { getTranslation, toDataUrl, OutletType } from "common";
import { useInfiniteScroll, useSnackbar, useTranslations, useVersions } from "hooks";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useOutletContext } from "react-router-dom";
import { Edit, ManageAccounts } from "@mui/icons-material";
import { RoleDialog } from "./dialogs";
import AvatarRem from "./AvatarRem";

interface RolesPanelProps {
  onSelect: Function;
  selectedId: number | null;
  organizationId: number;
  administrator: boolean;
}
export interface Role {
  creation?: string;
  id?: number;
  icon?: string | null;
  idorganization?: number;
  name?: string;
}
type Ref = { getRoles: Function } | undefined;

const INITIAL_PAGE = 1;
const PAGE_SIZE = 10;
const RolesPanel = forwardRef<Ref, RolesPanelProps>(
  ({ onSelect, organizationId, selectedId, administrator }, ref) => {
    const translations = useTranslations();
    // const user = useUser();
    // const rolesEvent = useRef<EventSource>(new EventSource(`${process.env.REACT_APP_BACKEND_BASE_URI}organizations/${organizationId}/roles/sse?jwt=${user.jwt}`));
    const { secondFilter: roleFilter } = useOutletContext<OutletType>();
    const [roles, setRoles] = useState<Array<Role>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [roleEdit, setRoleEdit] = useState<Role | null>(null);
    const [page, setPage] = useState<number>(INITIAL_PAGE);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const { sendSnack } = useSnackbar();
    const lastRoleRef = useInfiniteScroll(setPage, loading, hasMore);
    const mount = useRef<boolean>(false);
    const { getVersion, updateVersion } = useVersions();

    const getFirstRoles = useCallback(async () => {
      setLoading(true);
      try {
        const queryPage = `?page=${INITIAL_PAGE}&pagesize=${PAGE_SIZE}`;
        const filter = `&name=${roleFilter}`;
        const { data } = await organizationsApi.get(
          `${organizationId}/roles/v2${queryPage}${filter}`
        );
        setHasMore(Number(data.pagination.curr_page) < Number(data.pagination.total_pages));
        // setRoles([...data.roles]);
        setRoles(JSON.parse(JSON.stringify(data.roles)));
        setPage(INITIAL_PAGE);
      } catch {
      } finally {
        setLoading(false);
      }
    }, [organizationId, roleFilter]);

    const getRoles = useCallback(
      async (query: string) => {
        if (page !== INITIAL_PAGE) {
          setLoading(true);
          try {
            const queryPage = `?page=${page}&pagesize=${PAGE_SIZE}`;
            const filter = `&name=${query}`;
            const { data } = await organizationsApi.get(
              `${organizationId}/roles/v2${queryPage}${filter}`
            );
            setHasMore(Number(data.pagination.curr_page) < Number(data.pagination.total_pages));
            setRoles((_roles) => [..._roles, ...data.roles]);
          } catch {
          } finally {
            setLoading(false);
          }
        }
      },
      [organizationId, page]
    );

    useImperativeHandle(
      ref,
      () => ({
        getRoles: getFirstRoles,
      }),
      [getFirstRoles]
    );

    useEffect(() => {
      getRoles(roleFilter);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getRoles]);

    useEffect(() => {
      if (organizationId && mount.current) {
        getFirstRoles();
      }
      else if (!mount.current) {
        mount.current = true;
        getFirstRoles();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId, roleFilter]);

    // useEffect(() => {
    //   if (!mount.current) {
    //     mount.current = true;
    //     getFirstRoles();
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [organizationId]);

    useEffect(() => {
      return () => {
        mount.current = false;
        setPage(INITIAL_PAGE);
        setRoles([]);
      };
    }, [organizationId]);


    const handleSaveEdit = async (
      imageUrl: string | null,
      name: string | null
    ) => {
      try {
        let icon = null;
        if (imageUrl) {
          icon = await toDataUrl(imageUrl);
        }
        const updateApi = await rolesApi.put(`${roleEdit?.id}`, { name, icon });
        updateVersion(roleEdit?.id)
        setRoleEdit(null);
        sendSnack({
          message: `${updateApi.data.responsemessage}`,
          type: "success",
        });
        getFirstRoles();
      } catch {
      } finally {
      }
    };

    const handleDeleteRole = async () => {
      if (roleEdit) {
        try {
          const deleteApi = await rolesApi.delete(`${roleEdit.id}`);
          sendSnack({
            message: `${deleteApi.data.responsemessage}`,
            type: "success",
          });
          getFirstRoles();
          setRoleEdit(null);
        } catch {
        } finally {
        }
      }
    };


    return (
      <React.Fragment>
        <Stack height="100%" overflow="auto" flex={1} spacing={2}>
          <Stack height="100%">
            <Typography variant="subtitle1">
              {getTranslation(translations, "roles.name")}
            </Typography>
            <Divider flexItem sx={{ mb: 1 }} />
            <List id="roles-list" sx={{ overflow: "auto" }} disablePadding>
              {roles.map(({ icon, name, id, ...rest }, idx) => (
                // name?.toLowerCase().includes(roleFilter.toLowerCase()) && (
                <React.Fragment key={`role-${id}-${getVersion(id)}`}>
                  {roles.length === idx + 1 ? (
                    <ListItemButton
                      id={`role-${id}`}
                      ref={(node) => lastRoleRef(node)}
                      selected={selectedId === id}
                      onClick={() => onSelect(selectedId === id ? null : id)}
                    >
                      <ListItemAvatar sx={{ height: "40px", width: "40px" }}>
                        <AvatarRem icon={icon || ""}>
                          <ManageAccounts />
                        </AvatarRem>
                      </ListItemAvatar>
                      <ListItemText>{name}</ListItemText>
                      {selectedId === id && (administrator) && (
                        <ListItemSecondaryAction>
                          <Tooltip title={getTranslation(translations, "generic.edit_role.caption")}>
                            <IconButton
                              id={`btn-modify-role-${id}`}
                              size="medium"
                              color="secondary"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setRoleEdit({ icon, name, id, ...rest });
                              }}
                            >
                              <Edit fontSize="medium" />
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      )}
                    </ListItemButton>
                  ) : (
                    <ListItemButton
                      id={`role-${id}`}
                      selected={selectedId === id}
                      onClick={() => onSelect(selectedId === id ? null : id)}
                    >
                      <ListItemAvatar sx={{ height: "40px", width: "40px" }}>
                        <AvatarRem icon={icon || ""}>
                          <ManageAccounts />
                        </AvatarRem>
                      </ListItemAvatar>
                      <ListItemText>{name}</ListItemText>
                      {selectedId === id && (administrator) && (
                        <ListItemSecondaryAction>
                          <Tooltip title={getTranslation(translations, "generic.edit_role.caption")}>
                            <IconButton
                              id={`btn-modify-role-${id}`}
                              size="medium"
                              color="secondary"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setRoleEdit({ icon, name, id, ...rest });
                              }}
                            >
                              <Edit fontSize="medium" />
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      )}
                    </ListItemButton>
                  )}
                </React.Fragment>
              ))}
              {loading && (
                <Stack alignItems="center">
                  <CircularProgress />
                </Stack>
              )}
            </List>
          </Stack>
        </Stack>
        <RoleDialog
          loading={loading}
          open={Boolean(roleEdit)}
          role={roleEdit}
          onDelete={handleDeleteRole}
          onClose={() => setRoleEdit(null)}
          onSave={handleSaveEdit}
        />
      </React.Fragment>
    );
  }
);

export default RolesPanel;
