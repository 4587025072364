import React, { useContext, useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addMessage } from "Redux/messageReducer";
import { RootState } from "store";
/***********************************
 ****** COMPONENT FOR ROUTING ******
 ***********************************/
// Added indexes to directories and now ./Pages export all pages in its directories
import {
  // Password, // to see if we have to have a page with reset password form
  Welcome,
  ForgotPassword,
  Dashboard,
  Process,
  NewProcessModel,
} from "./Component/Pages";
import {
  Login,
  Organizations,
  MyActivities,
  Notifications,
  NotFound,
  Processes,
  Password,
  Registration,
  Email,
  Organization,
  Invitation,
  ExternalInvitation,
  Statistics,
  Messages,
  Robots,
  RobotsHelp,
  PrivacyAndPolicy,
  Scheduled,
  // Scheduled,
} from "pages";
import SnackbarModel from "model/snackbarModel";
import { Box, Chip, Stack } from "@mui/material";
import Message from "./components/Message";
import { noTokenApi } from "api";
import { AuthContext, UtilityContext } from "contexts";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Profile from "pages/Profile";
import { CONSTANTS } from "common";
import { useVersions } from "hooks";
import { trackPageView } from "common/utilities";

function App() {
  const dispatch = useDispatch();
  const {
    actions: { removeOrganizationsFirstSearch },
  } = useContext(UtilityContext);
  const {
    state: { checkInApp, user },
    actions: { setUserAndJwt },
  } = useContext(AuthContext);
  const snackbars: Array<SnackbarModel> = useSelector(
    (state: RootState) => state.snackbar
  );
  const { getVersion, updateVersion } = useVersions();
  const [lastVersion, setLastVersion] = useState(process.env.REACT_APP_VERSION || "");

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    const wsUrl = process.env.NODE_ENV === "development" ? `${process.env.REACT_APP_STATS_BACKEND_BASE_URI}/version` : `${process.env.REACT_APP_WEBSOCKET_PROTOCOL}://${location.host}${process.env.REACT_APP_STATS_BACKEND_BASE_URI}/version`;
    const ws = new WebSocket(wsUrl)
    ws.onopen = () => {
      ws.onmessage = ({ data: version }) => {
        if(version !== lastVersion) {
          setLastVersion(version);
        }
      };
    }
    return () => {
      ws.close();
    }
  }, [lastVersion]);

  const _location = useLocation();

  useEffect(() => {
    trackPageView(_location.pathname + _location.search);
  }, [_location]);

  useEffect(() => {
    const loggedUser = JSON.parse(
      localStorage.getItem(CONSTANTS.STORE_USER) || "null"
    );
  //  console.log("roba dell'user nel localStorage:", loggedUser);
    if (loggedUser) {
      setUserAndJwt(loggedUser);
    } else {
      setUserAndJwt(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (checkInApp) {
      (async () => {
        const result = await noTokenApi.get(
          `messages/${user?.i18n || navigator.language.split("-")[0]}`
        );
        dispatch(addMessage(result.data.messages));
      })();
    }
  }, [dispatch, user, checkInApp]);

  useEffect(() => {
    if (!user) {
      removeOrganizationsFirstSearch({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return !checkInApp ? null : (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={user?.i18n || navigator.language.split("-")[0]}
    >
      <Stack
        id="message"
        spacing={2}
        sx={{
          zIndex: (_) => 9999999999999,
          position: "fixed",
          right: (_) => _.spacing(2),
          top: (_) => _.spacing(2),
          maxWidth: "50%",
        }}
      >
        {snackbars.map((_) => (
          <Message key={_.id} {..._} />
        ))}
      </Stack>
      <Routes>
        <Route path="/privacy-policy" element={<PrivacyAndPolicy />} />
        <Route path="/invitation" element={<Invitation />} />
        <Route path="/externalinvitation" element={<ExternalInvitation />} />
        {!user && (
          <React.Fragment>
            <Route path="/" element={<Email />} />
            <Route path="/login" element={<Login />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/password" element={<Password />} />
            <Route path="/organization" element={<Organization />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
          </React.Fragment>
        )}
        {/******************************
         ****** PROTECTED ROUTES ******
         ******************************/}
        <Route
          path="/dashboard"
          element={
            <Protected isLoggedIn={Boolean(user?.jwt)}>
              <Dashboard newestVersion={lastVersion} getVersion={getVersion} />
            </Protected>
          }
        >
          <Route path="organizations" element={<Organizations />} />
          <Route path="processes" element={<Processes />} />
          <Route path="schedules" element={<Scheduled />} />
          <Route path="myactivities" element={<MyActivities />} />
          <Route path="process" element={<Process />} />
          <Route path="process/insert" element={<NewProcessModel />} />
          <Route
            path="profile"
            element={<Profile updateVersion={updateVersion} />}
          />
          <Route
            path="/dashboard/process/edit/:editId/"
            element={<NewProcessModel />}
          />
          <Route path="statistics" element={<Statistics />} />
          <Route path="robots" element={<Robots />} />
          <Route path="robots/help" element={<RobotsHelp />} />
          <Route path="notifications" element={<Notifications />} />

          {user?.email === "d.amici@bittime.it" && (
            <Route path="messages" element={<Messages />} />
          )}
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route
          path="*"
          element={<Navigate to="/dashboard/organizations" replace />}
        />
      </Routes>
      <Box
        sx={{
          zIndex: 9999999999999,
          position: "fixed",
          bottom: (_) => _.spacing(1),
          right: (_) => _.spacing(1),
        }}
      >
        <Chip
          size="small"
          color="primary"
          label={`v${process.env.REACT_APP_VERSION}`}
        />
      </Box>
    </LocalizationProvider>
  );
}

interface ProtectedProps {
  isLoggedIn: boolean;
}
const Protected: React.FC<React.PropsWithChildren<ProtectedProps>> = ({
  isLoggedIn,
  children,
}) => {
  if (!isLoggedIn) {
    return <Navigate to="/" replace />;
  }
  return <React.Fragment>{children}</React.Fragment>;
};

export default App;
