/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Validation, isRequired } from "hooks/useForm";
import { noTokenApi } from "api";
import {
  IconButton,
  Grid,
  TextField,
  Button,
  Container,
  Card,
  CardHeader,
  CardContent,
  Box,
} from "@mui/material";
import { useTranslations, useForm, useSnackbar } from "hooks";
import { getTranslation } from "common";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { AuthContext } from "contexts";

interface ExternalInvitationForm {
  lastname: String;
  firstname: String;
  password: String;
  confirmPassword: String;
  organization: String;
}

const ExternalInvitation = () => {
  const {actions: {setRegisterUser}} = useContext(AuthContext)
  const navigate = useNavigate();
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
    useState(false);
  const translations = useTranslations();
  const { sendSnack } = useSnackbar();
  const [searchParams] = useSearchParams();
  const jwt = searchParams.get("jwt");
  const idOrg = searchParams.get("idorganization");
  const emailParam = searchParams.get("email");
  // const organizationInitial: string | undefined = getTranslation(
  //   translations,
  //   "register.label.edit_myOrganization"
  // );
  const acceptInvitation = async () => {
    try {
      if (isNaN(Number(idOrg))) {
        sendSnack({
          type: "error",
          message: getTranslation(
            translations,
            "generic.something_went_wrong.caption"
          ),
        });
      } else {
        await noTokenApi.post(`/users/${jwt}/accept`, {
          idorganization: idOrg,
        });
        sendSnack({
          type: "success",
          message: getTranslation(
            translations,
            "externalinvitation.message.accepted"
          ),
        });
      }
    } catch {
    } finally {
    }
  };
  useEffect(() => {
    if (!idOrg || !jwt) {
      navigate("/");
    }
  }, [navigate]);

  const validations: Validation[] = [
    ({ firstname }) =>
      isRequired(firstname) || {
        firstname: getTranslation(translations, "register.fieldrequired"),
      },
    ({ lastname }) =>
      isRequired(lastname) || {
        lastname: getTranslation(translations, "register.fieldrequired"),
      },
    ({ password }) =>
      isRequired(password) || {
        password: getTranslation(translations, "register.fieldrequired"),
      },
    ({ confirmPassword }) =>
      isRequired(confirmPassword) || {
        confirmPassword: getTranslation(translations, "register.fieldrequired"),
      },
  ];

  const { values, changeHandler, errors, touched, isValid } = useForm(
    {
      email: emailParam ?? "",
      firstname: "",
      lastname: "",
      password: "",
      confirmPassword: "",
    },
    validations
  );

  const saveData = async (data: ExternalInvitationForm) => {
    const { password, confirmPassword, firstname, lastname } = data;
    if (password !== confirmPassword) {
      changeHandler("confirmPassword", "");
      sendSnack({
        message: getTranslation(translations, "register.passwordnotequal"),
        type: "error",
      });
      return;
    } else {
      try {
        await noTokenApi.put(`/users/registration/${jwt}`, {
          lastname: lastname,
          firstname: firstname,
          password1: password,
          password2: confirmPassword,
        });
        setRegisterUser({
          jwt: jwt || "",
        });
        await acceptInvitation();
        navigate("/welcome");
      } catch {
      } finally {
      }
    }
  };

  return (
    <Box width="100%" height="100%">
      <Container
        maxWidth="sm"
        sx={{
          justifyContent: "center",
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={getTranslation(
              translations,
              "register.label.register_an_account"
            )}
          />
          <CardContent>
            <Grid container component="form" spacing={2}>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  label={getTranslation(
                    translations,
                    "register.registration.name.label"
                  )}
                  fullWidth
                  error={Boolean(touched.firstname && errors.firstname)}
                  helperText={touched.firstname && errors.firstname}
                  value={values.firstname}
                  onChange={({ target }) =>
                    changeHandler("firstname", target.value)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  label={getTranslation(
                    translations,
                    "register.registration.lastname.label"
                  )}
                  fullWidth
                  error={Boolean(touched.lastname && errors.lastname)}
                  helperText={touched.lastname && errors.lastname}
                  value={values.lastname}
                  onChange={({ target }) =>
                    changeHandler("lastname", target.value)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  type={passwordVisibility ? "text" : "password"}
                  label={getTranslation(
                    translations,
                    "password.label.newpassword"
                  )}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  value={values.password}
                  onChange={({ target }) =>
                    changeHandler("password", target.value)
                  }
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => setPasswordVisibility((_) => !_)}
                      >
                        {passwordVisibility ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  type={confirmPasswordVisibility ? "text" : "password"}
                  label={getTranslation(
                    translations,
                    "password.label.confirmpassword"
                  )}
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  value={values.confirmPassword}
                  onChange={({ target }) =>
                    changeHandler("confirmPassword", target.value)
                  }
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => setConfirmPasswordVisibility((_) => !_)}
                      >
                        {confirmPasswordVisibility ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  type="submit"
                  size="small"
                  disabled={!isValid}
                  onClick={(e) => {
                    e.preventDefault();
                    saveData(values);
                  }}
                >
                  {getTranslation(
                    translations,
                    "register.label.button_register_confirm"
                  )}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default ExternalInvitation;
